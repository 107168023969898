
import debounce from 'debounce'
import headerScroll from '@/mixins/headerScroll'

export default {
  name: 'TheHeader',

  mixins: [
    headerScroll,
  ],

  data () {
    return {
      isNavbarOpen: false,
    }
  },

  methods: {
    setNavbarStatus: debounce(function (value) {
      this.isNavbarOpen = value
    }, 10),

    onToggle () {
      this.setNavbarStatus(!this.isNavbarOpen)
    },

    onClose () {
      this.setNavbarStatus(false)
    },
  },
}
