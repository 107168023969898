
import storeCode from '~/mixins/storeCode'

export default {
  name: 'HeaderLogo',
  mixins: [storeCode],
  props: {
    desktopOnly: {
      type: Boolean,
      default: false,
    },

    isCheckout: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tag () {
      return this.isMedicoverAptekaStore ? 'div' : 'h1'
    },
  },
}
