const middleware = {}

middleware['getSalesManagoClientId'] = require('../middleware/getSalesManagoClientId.js')
middleware['getSalesManagoClientId'] = middleware['getSalesManagoClientId'].default || middleware['getSalesManagoClientId']

middleware['hasAccount'] = require('../middleware/hasAccount.js')
middleware['hasAccount'] = middleware['hasAccount'].default || middleware['hasAccount']

middleware['hasRedirect'] = require('../middleware/hasRedirect.js')
middleware['hasRedirect'] = middleware['hasRedirect'].default || middleware['hasRedirect']

middleware['isAuthed'] = require('../middleware/isAuthed.js')
middleware['isAuthed'] = middleware['isAuthed'].default || middleware['isAuthed']

middleware['isAuthedMediclubMember'] = require('../middleware/isAuthedMediclubMember.js')
middleware['isAuthedMediclubMember'] = middleware['isAuthedMediclubMember'].default || middleware['isAuthedMediclubMember']

middleware['isBlogPage'] = require('../middleware/isBlogPage.js')
middleware['isBlogPage'] = middleware['isBlogPage'].default || middleware['isBlogPage']

middleware['isGuest'] = require('../middleware/isGuest.js')
middleware['isGuest'] = middleware['isGuest'].default || middleware['isGuest']

middleware['isGuestCheckout'] = require('../middleware/isGuestCheckout.js')
middleware['isGuestCheckout'] = middleware['isGuestCheckout'].default || middleware['isGuestCheckout']

middleware['isMediclubMember'] = require('../middleware/isMediclubMember.js')
middleware['isMediclubMember'] = middleware['isMediclubMember'].default || middleware['isMediclubMember']

export default middleware
