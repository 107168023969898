import blogStaticPageUrlKeys from '~/config/blogStaticPageUrlKeys'
import blogsEnabledForWL from '~/config/blogEnabledForWL'

export default function ({ app, route, $config, error }) {
  const { pathMatch } = route.params

  const isStaticPage = blogStaticPageUrlKeys.includes(pathMatch)
  const isBlogPageEnabled = blogsEnabledForWL.includes($config.storeCode)

  if (!isBlogPageEnabled && !isStaticPage) {
    return error({
      statusCode: 404,
      message: app.i18n.t('Article.Article does not exist'),
    })
  }
}
