export default {
  methods: {
    goToUrl (url, { translate = false } = {}) {
      if (url?.startsWith?.(this.$config.appUrl) || url?.startsWith?.('/')) {
        const path = url.replace(this.$config.appUrl, '')

        this.$router.push(translate ? this.localePath(path) : path)
      } else {
        window.open(url, '_blank')
      }
    },
  },
}
