export default {
  computed: {
    isMedicoverAptekaStore () {
      return this.$config.storeCode === 'medicoverapteka'
    },

    isSynevoStore () {
      return this.$config.storeCode === 'synevo'
    },

    isMedistoreStore () {
      return this.$config.storeCode === 'default'
    },

    isMindhealthStore () {
      return this.$config.storeCode === 'mindhealth'
    },

    isRehasportStore () {
      return this.$config.storeCode === 'rehasport'
    },

    isDamianStore () {
      return this.$config.storeCode === 'damian'
    },

    isMedicoverStore () {
      return this.$config.storeCode === 'medicover'
    },
  },
}
