
export default {
  name: 'HeaderInfoBar',

  data () {
    return {
      isLocaleOpen: false,
    }
  },

  computed: {
    infobar () {
      return this.$store.state.storeConfig?.storeConfig?.infoBarConfig
    },
  },

  methods: {
    changeLocale (code) {
      this.$cookies.remove('compareProductList')

      let localePath = this.switchLocalePath(code)

      if (localePath.endsWith('#')) {
        localePath = localePath.slice(0, -1)
      }
      window.location.href = localePath
    },

    localeToggle () {
      this.isLocaleOpen = !this.isLocaleOpen
    },

    onCloseLocale () {
      this.isLocaleOpen = false
    },
  },
}
