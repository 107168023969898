export default [
  'o-nas',
  'regulamin',
  'polityka-prywatnosci',
  'klauzula-informacyjna',
  'faq',
  'regulamin-mediclub',
  'polityka-cookies',
  'polityka-prywatnosci-serwisu-mediclub',
  'platnosc-ratalna',
  'plasowanie-produktow',
  'regulamin-integracji-b2c',
  'politica-de-confidentialitate',
  'termeni-si-conditii',
  'kontakt',
  'paypo',
]
