export default function () {
  return {
    category: [],
    categoryDisplayMode: {},
    footer: [],
    storeConfig: {},
    storeReview: null,
    recaptchaMutations: [],
    recaptchaQueries: [],
    megaCategory: [],
  }
}
