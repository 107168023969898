import { render, staticRenderFns } from "./medistore.vue?vue&type=template&id=1c1a2494&scoped=true&"
import script from "./medistore.vue?vue&type=script&lang=js&"
export * from "./medistore.vue?vue&type=script&lang=js&"
import style0 from "./medistore.vue?vue&type=style&index=0&id=1c1a2494&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c1a2494",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsHelloBar: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/CmsHelloBar.vue').default,BaseStaticBlock: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseStaticBlock.vue').default,TheHeader: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/TheHeader.vue').default,ThePages: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/vendor/default/components/ThePages.vue').default,CmsFooterLinks: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/CmsFooterLinks.vue').default,BaseToast: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/toast/BaseToast.vue').default})
