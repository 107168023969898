
export default {
  name: 'BaseDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    closeOnBackdrop: {
      type: Boolean,
      default: false,
    },

    slideInFromRight: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isOpen: this.value,
      scale: false,
    }
  },

  computed: {
    transitionNameFadeSlide () {
      return this.slideInFromRight ? 'slide-right-fade-slide' : 'fade-slide'
    },
  },

  watch: {
    value: {
      handler (value) {
        this.isOpen = value
      },

      immediate: true,
    },

    isOpen (open) {
      if (process.client) {
        open ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden')
      }
    },
  },

  methods: {
    open () {
      this.isOpen = true
      this.$emit('input', true)

      document.activeElement?.blur?.()
    },

    close () {
      this.isOpen = false
      this.$emit('input', false)

      document.activeElement?.blur?.()
    },

    onBackdropClick () {
      if (this.closeOnBackdrop) {
        this.close()
        this.$emit('backdrop-click')
      } else {
        this.scale = true
      }
    },

    onScaleEnd () {
      this.scale = false
    },
  },
}
